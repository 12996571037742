import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import Amplify from 'aws-amplify';
import config from 'config.json';

import './index.css';

import App from 'app/App';
import store from 'state/store';
import { getAuthUser } from 'state/slices/authSlice';

import { Chart as ChartJS, CategoryScale, Legend, Title, Tooltip } from 'chart.js';
import { MatrixController, MatrixElement } from 'chartjs-chart-matrix';
ChartJS.register(MatrixController, MatrixElement, CategoryScale, Legend, Title, Tooltip);

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.AWSRegion,
    userPoolId: config.UserPoolId,
    identityPoolId: config.IdentityPoolId,
    userPoolWebClientId: config.UserPoolClientId,
  },
  API: {
    endpoints: [
      {
        name: 'api',
        endpoint: config.APIEndpoint,
        region: config.AWSRegion,
      },
    ],
  },
  Storage: {
    level: 'private',
    customPrefix: {
      private: 'users/',
    },
    AWSS3: {
      bucket: config.GroundTruthBucketName,
      region: config.AWSRegion,
    },
  },
});

store.dispatch(getAuthUser());

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
