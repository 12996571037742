import React, { useState } from 'react';
import { Storage } from 'aws-amplify';

import PrivateTemplate from '../components/PrivateTemplate';

export const Upload = () => {
  const [targetFile, setTargetFile] = useState(null);

  const handleSubmit = e => {
    e.preventDefault();
    if (!targetFile) return;
    Storage.put(`${targetFile.lastModified}/${targetFile.name}`, targetFile, { contentType: targetFile.type });
  };

  const handleChange = e => {
    const [file, ...rest] = e.target?.files; // eslint-disable-line
    if (!file) {
      setTargetFile(null);
    } else {
      setTargetFile(file);
    }
  };

  return (
    <PrivateTemplate headerTitle={'Admin › Bulk Label'}>
      <form className="mt-10 px-4" onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="manifestFile" className="mb-2 inline-block text-neutral-700 dark:text-neutral-200">
            Upload Sagemaker Label Manifest
          </label>
          <input
            className="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding py-[0.32rem] px-3 text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[margin-inline-end:0.75rem] file:[border-inline-end-width:1px] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100"
            type="file"
            id="manifestFile"
            onChange={handleChange}
          />
        </div>
        <div className="pt-5">
          <div className="flex justify-end">
            <button
              type="submit"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-800 hover:bg-indigo-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700">
              Upload
            </button>
          </div>
        </div>
      </form>
    </PrivateTemplate>
  );
};

export default Upload;
